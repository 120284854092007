<template>
  <span class="card" @click="$emit('show-card', item.id)">
    <label class="label">
      <v-icon style="font-size: 24px; padding-right: 12px" @click.native.stop="$emit('click-action', item)">mdi-checkbox-marked-circle-plus-outline </v-icon>
      {{ item.source }}
    </label>

    <div class="kanban-action">
      <label>
        <strong><a-view-taskname :value="item.name" :values="item" /></strong>
      </label>
    </div>

    <label class="label">
      <div class="d-flex">
        <v-icon style="font-size: 14px; padding-right: 12px" :color="item.user_id ? 'green' : 'red'">far fa-user</v-icon>
        <a-view-table-select :value="item.user_id" :model="{ type: 'select', dir: 'user' }" />
      </div>
    </label>
    <small><a-view-table-datetime :value="item.updatedon || item.createdon" /></small>
    <v-lazy v-model="lazy" :options="{ threshold: 0.5 }" :key="item.id">
      <span v-if="lazy">
        <v-icon v-if="load_task == 3" style="font-size: 14px; padding-right: 12px" color="red">fas fa-exclamation-triangle</v-icon>
        <v-progress-circular v-if="load_task == 1" indeterminate color="green" />
        <v-chip class="mt-1" v-if="load_task == 2" v-for="task in tasks" :key="task.id" small @click.native.stop="$emit('click-task', task)">{{
          getTaskName(task.type)
        }}</v-chip>
      </span>
    </v-lazy>
  </span>
</template>

<script>
import { getAccess, autoHeightBlock, genModel } from "@/components/mixings";
export default {
  mixins: [getAccess, autoHeightBlock, genModel],
  name: "LeadsCardKanban",
  props: {
    item: Object,
  },
  components: {},
  data() {
    return {
      lazy: false,
      m: this.$store.getters["config/get"].models.leads,
      load_task: 0,
      tasks_: [],
      type: [...this.$store.state.config.data.config.dictionary.lead_actions],
    };
  },

  computed: {
    tasks() {
      let res = [];
      if (!this.load_task) {
        this.getTasks();
      }
      if (this.load_task == 2) res = this.tasks_;
      return res;
    },
  },
  mounted() {},
  methods: {
    getTaskName(v) {
      return (this.type.find((t) => t.value == v) || {})?.text || v;
    },

    async getTasks() {
      this.load_task = 1;
      try {
        const params = { filters: { lead_id: this.item.id, status: 1 } };
        let d = await this.$axios.get(this.m.tasks.api, { params });
        this.tasks_ = [...d.data.data];
        this.load_task = 2;
      } catch (error) {
        this.load_task = 3;
        // this.$root.$emit("show-info", { text: "Ошибка загрузки данных" });
      }
    },
  },
};
</script>
